<template lang="">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                      <div class="row">
                        <div class="col-md-4">
                            <h3>My Invoice Lists</h3>
                        </div>
                        <div class="col-md-4">
                          <input type="text" placeholder='Search' v-model='search_invoice' @keyup='searchInvoice' class="form-control">
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
    <div class="table-responsive">
    <table class="table table-bordered table-striped table-sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Invoice Number</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Due Date</th>
          <th>Paid</th>
          <th>Total(TZS)</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(invoice, index) in invoices" :key="invoice.id">
          <td>{{ index + 1 }}</td>
          <td>{{ invoice.invoice_number }}</td>
          <td>{{ invoice.start_date }}</td>
          <td>{{ invoice.end_date }}</td>
          <td>{{ invoice.due_date }}</td>
          <td>
            <InvoiceStatus :status="invoice.status"/>
          </td>
          <td>{{ invoice.total_amount }}</td>
          <td>{{ invoice.created_at }}</td>
       
          <td>
            <div class="btn-group">
           
              <button
              v-if='index==0 && !invoice.is_paid'
              
              @click='showInvoice(invoice)'
                type="button"
                class="btn btn-danger"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-eye"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </button>

              <button
              v-else-if='invoice.is_paid'
              
              @click='showInvoice(invoice)'
                type="button"
                class="btn btn-dark"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-eye"
                >
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </button>

              <button
              v-else
              
              @click='showInfo(invoice)'
                type="button"
                class="btn btn-info text-white"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>
              </button>
          
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
                    </div>

                    <div class="card-footer d-flex justify-content-center">
                      <data-pagination   
                      :data="pageData"
                      :limit="limit"
                       @pagination-change-page="getInvoices">
                      
                      </data-pagination>
                  
                    </div>

                </div>
            </div>
        </div>
    </div>

  <l-modal v-if='showModal' @close='closeModal'>
    <template v-slot:header>
      <h4>{{invoice.invoice_number}}</h4>
    </template>
<template v-slot:body>
      <InvoiceDetail :invoice='invoice'/>
    </template>
</l-modal>

<m-modal v-if='showInfoModal' @close='showInfoModal=false'>
  <template v-slot:header>
      <h4>Info</h4>
    </template>
  <template v-slot:body>
     Please pay the oldest invoice(s) 
    </template>
</m-modal>

</template>
<script>
import axios from 'axios';
import InvoiceDetail from './InvoiceDetail.vue';


import InvoiceStatus from '../common/InvoiceStatus.vue'

export default {
  components: {
    InvoiceDetail,
    InvoiceStatus,
  },

  data() {
    return {
      invoices: [],
      invoice: {},
      pageData: {},
      limit: 2,
      showModal: false,
      showInfoModal: false,
      search_invoice: ""
    }
  },
  created() {
    this.getInvoices();
  },

  watch: {
    search_invoice(after, before) {
      console.log(after);
      console.log(before);
      this.searchInvoice();
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.invoice = {}
    },
    getInvoices(page = 1) {
      let loader = this.$loading.show();
      axios.get('/customer/invoices?page=' + page)
        .then(response => {
          loader.hide();
          this.invoices = response.data.data;
        }).catch(error => {
          loader.hide();
          console.log(error);
        })
    },
    showInvoice(invoice) {
      this.invoice = invoice;
      this.showModal = true;
    },
    searchInvoice(page = 1) {
      axios
        .get("/search/invoices?page=" + page, {
          params: { search_invoice: this.search_invoice },
        })
        .then((response) => {
          this.invoices = response.data.data;
          this.pageData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showInfo(invoice) {
      this.invoice = invoice;
      this.showInfoModal = true;

    }
  }

}
</script>
<style lang="">

</style>