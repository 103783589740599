<template lang="en">
   <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Invoice Info</button>
  </li>

  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Receipt</button>
  </li>
 
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
    <InvoiceCard :invoice='invoice' />
  </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
    <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <h4> This is coming soon. </h4>
          </div>
        </div>     
    </div>
  </div>

</div>
</template>
<script>
import InvoiceCard from './InvoiceCard.vue';
export default {
    props:['invoice'],
    components:{
        InvoiceCard
    },   
}
</script>
<style lang="">
    
</style>