<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">TERNET</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
          </li>
        </ul>
        <ul class="navbar-nav  mb-2 mb-lg-0">
          <li class="nav-item" v-if="!isAuthenticated">
            <router-link class="nav-link" to="/login">Login</router-link>
          </li>

          <li class="nav-item dropdown" v-if="isAuthenticated">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ customer.name }}
            </a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/profile">Profile</router-link></li>
              <li><a class="dropdown-item" @click.prevent="logout" href="#">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {

  data() {
    return {
      isAuthenticated: false
    }
  },

  mounted() {
    this.getToken();
    this.isAuthenticated = !!localStorage.getItem('auth');
  },



  computed: {
    ...mapGetters(['customer', 'isLoggedIn', 'token']),

  },
  created() {
    this.getCustomer();
  },
  methods: {
    ...mapActions(['getCustomer', 'getToken']),

    logout() {
      const token = localStorage.getItem('token');
      let loader = this.$loading.show()
      axios.get('/logout', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          console.log(response);

          loader.hide();
          localStorage.removeItem('auth');
          localStorage.removeItem('token');
          this.$router.push('/');

        }).catch(error => {
          console.log(error);
          loader.hide();
            localStorage.removeItem('auth');
            localStorage.removeItem('token');
            useRouter().replace('/')
          
        })
    }
  }


}
</script>
<style lang="">

</style>