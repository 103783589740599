<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <navigation></navigation>
        <div class="my-5">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from './components/partials/AppNavigation'
export default {

  components: {
    Navigation
  }
}
</script>

<style>
#app {

  color: #2c3e50;
 
}
</style>
