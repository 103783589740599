<template lang="">
  <div class='container my-3'>
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h4> Invoices</h4>
              <h6>{{invoiceInfo.total_invoices}}</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h4>Paid Invoices</h4>
              <h6>{{invoiceInfo.paid_invoices}}</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h4>UnPaid Invoices</h4>
              <h6>{{invoiceInfo.unpaid_invoices}}</h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h4>Overdue Invoices </h4>
              <h6>{{invoiceInfo.overdue_invoices}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-3">
      <InvoiceList/>
    </div>
    
</template>
<script>
import InvoiceList from '../invoices/InvoiceList'
import axios from 'axios'
export default {
  components: {
    InvoiceList
  },
  data() {
    return {
      invoiceInfo: {}
    }
  },
  created(){
    this.getDashboard();
  },
  methods: {
    getDashboard() {
      axios.get('/customer-dashboard').then(response => {
        console.log(response.data);
        this.invoiceInfo = response.data.dashboard;

      }).catch(error => {
        console.log(error);
      })
    }

  }



}
</script>
<style lang="">
    
</style>