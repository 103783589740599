<template >
  <div class="container">
    <div class="row">
      <div class="col-md-4 offset-4">
        <div class="card">
          <div class="card-header">
            <h3>Login to your account</h3>
          </div>
          <div class="card-body">
            {{ error }}
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="text" placeholder="Email" v-model="formData.email" class="form-control">
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input type="password" placeholder="Password" v-model="formData.password" class="form-control">
            </div>

            <div class="mb-3">
              <button @click="login" class="btn btn-danger w-100">Login</button>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-center">
            <router-link to="forgot-password">Forgot Password?</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {mapMutations} from 'vuex'

export default {
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      error:''
    }

  },

  methods: {
    ...mapMutations(["setToken"]),


    login(){
      let loader=this.$loading.show();
      axios.post('/login',this.formData)
      .then(response=>{
        loader.hide();
        if(response.data.success){
          this.setToken(response.data.access_token);
          localStorage.setItem('token',response.data.access_token);
          localStorage.setItem('auth',true);
          this.$router.replace('dashboard');
        }else{
          loader.hide();
          this.$toast.error(response.data.message);

        }

      }).catch(error=>{
        loader.hide();
        this.error=error;

      });
    }
  
  }

}
</script>
<style></style>