import axios from "axios"

export default {
    state: {
    
        customer: {},
        token:"",
        isLoggedIn:false
    
    },

    actions: {
  
        getCustomer({ commit }) {
            axios.get('/profile')
                .then(response => {
                    if (response.data.success) {
                        commit('GET_CUSTOMER', response.data.customer);
                    } else {
                        this.$toast.error(response.data.error);
                    }

                }).catch(error => {
                    console.log(error);
                });
        },

        getToken({commit}){
            let token=localStorage.getItem('token');
            commit('GET_TOKEN',token);
        }

    },

    mutations: {

    
        GET_CUSTOMER(state, customer) {
            state.customer = customer;
        },

        GET_TOKEN(state, token) {
            state.token = token;
          },
      
      
    },

    getters: {

        isLoggedIn(state) {
            return !!state.token;
          },
      
     
        customer(state) {
            return state.customer;
        },
     
    }
}
