<template>
    <div>
        <div class="modal " style="display: block;"  tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-md" role="document">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <slot name="header"></slot>
                        </h4>
                        <button type="button" class="close" @click="$emit('close')" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                        <button type="button" @click="$emit('close')" class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default{

        data(){
            return{
                showModel:false
            }
        }
    }
</script>

<style scoped>

.modal{
    background-color: rgba(173, 173, 173, 0.3);
}

</style>
