<template lang="">
   <div class="container">
  <div class="row">
    <div class="col-md-4 offset-4">
      <div class="card">
        <div class="card-header">
          <h3>Reset Your Password</h3>
        </div>
        <div class="card-body">
      
          <div class="mb-3">
            <label for="password" class="form-label">New Password</label>
            <input type="password" placeholder="Password"  v-model='password' class="form-control">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Confirm Password</label>
            <input type="password" placeholder="Confirm Password"  v-model='password_confirmation' class="form-control">
          </div>

          <div class="mb-3">
            <button @click='resetPassword' class="btn btn-danger w-100">Reset Password</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios';

export default {

  data(){
    return{
      password:"",
      password_confirmation:"",
    
    }
  },

  methods:{
    resetPassword(){
      let data={
        password:this.password,
        password_confirmation:this.password_confirmation,
        token:this.$route.params.token
      };
      let loader=this.$loading.show();
      axios.post('/reset-password',data)
      .then(response=>{
        loader.hide();
        if(response.data.success){
          this.$toast.success(response.data.message);
          this.$router.push('/');

        }else{
          this.$toast.error(response.data.message);
        }

      }).catch(error=>{
        loader.hide();
        this.$toast.error(error);

      });
    },
 
  }
    
}
</script>
<style lang="">
    
</style>