import { createApp } from 'vue'
import App from './App.vue'
import routes from './routes'
import axios from 'axios'
import store from './store'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import LargeModal from './components/common/LargeModal'
import MediumModal from './components/common/MediumModal'
import { Bootstrap5Pagination } from 'laravel-vue-pagination';
import Toaster from "@meforma/vue-toaster";


const app=createApp(App)
app.use(store)
app.use(Toaster,{
    position: "top",
})
app.use(LoadingPlugin,{
    color:'red',
    height:128,
    width:128
})

app.component('l-modal',LargeModal)
app.component('m-modal',MediumModal)
app.component('data-pagination',Bootstrap5Pagination)

axios.defaults.baseURL=process.env.VUE_APP_BILLING_BASE_URL;
axios.defaults.headers.common['Content-Type']='application/json';
axios.defaults.headers.common['Accept']='application/json';

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        if(error.response.status==401){
        
            this.$router.push('login');
        }
        return Promise.reject(error);
    }
);

app.use(routes)
app.mount('#app')


