import Login from '../components/auth/CustomerLogin'
import ResetPassword from '../components/auth/ResetPassword'
import ForgotPassword from '../components/auth/ForgotPassword'
import Dashboard from '../components/dashboard/CustomerDashboard'
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/partials/HomePage'
import UserProfile from '../components/auth/UserProfile'
import VerifyCode from '../components/auth/VerifyCode'


const routes=[
    {
        path:'/login',
        component:Login,
        name:'login',
        meta:{guestOnly:true}
    },
    {
        path:'/',
        component:HomePage,
        name:'home'
    },
    {
        path:'/forgot-password',
        component:ForgotPassword,
        meta:{guestOnly:true}
    },
    {
        path:'/reset-password/:token',
        name:'reset',
        component:ResetPassword,
        meta:{guestOnly:true}
    },
    {
      path:'/verify-code/:token',
      name:'verifyCode',
      component:VerifyCode,
      meta:{guestOnly:true}
  },
    {
        path:'/dashboard',
        component:Dashboard,
        meta:{authOnly:true}
    },
    {
        path:'/profile',
        component:UserProfile,
        meta:{authOnly:true}
    }
];
const router=createRouter({
    history:createWebHistory(),
    routes:routes
})
function isAuthenticated(){
    return localStorage.getItem('auth');
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authOnly)) {
      // Check if the user is authenticated
      if (!isAuthenticated()) {
        // If not, redirect to login page
        next({
          path: '/login',
          query: { redirect: to.fullPath } // Save the attempted URL for redirecting after login
        });
      } else {
        // If authenticated, proceed to the route
        next();
      }
    } else   if (to.matched.some(record => record.meta.guestOnly)) {
        // Check if the user is authenticated
        if (isAuthenticated()) {
          // If not, redirect to login page
          next({
            path: '/dashboard',
            query: { redirect: to.fullPath } // Save the attempted URL for redirecting after login
          });
        } else {
          // If authenticated, proceed to the route
          next();
        }
      }
    
    else {
      // If the route doesn't require authentication, proceed
      next();
    }
  });

export default router;