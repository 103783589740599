<template lang="">
    <div class="container">
   <div class="row">
     <div class="col-md-4 offset-4">
       <div class="card">
         <div class="card-header">
           <h3>Verify Code</h3>
         </div>
         <div class="card-body">
           <div class="mb-3">
             <label for="email" class="form-label">Code</label>
             <input type="number" placeholder="Enter code" v-model='code' class="form-control">
           </div>
        
 
           <div class="mb-3">
             <button @click='verifyCode' class="btn btn-danger w-100">Verify</button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
 </template>
 <script>
 import axios from 'axios';
 export default {

    data(){
        return{
            code:""
        }
    },
    methods:{
        verifyCode(){
            let data={
                code:this.code,
                token:this.$route.params.token
            };
            let loader=this.$loading.show();
            axios.post('/verify-code',data)
            .then(response=>{
                loader.hide();
                if(response.data.success){
                  this.$toast.success(response.data.message);
                    const token=response.data.token;
                    this.$router.push({name:'reset',params:{'token':token}});

                }else{
                    this.$toast.error(response.data.message);
                }

            }).catch(error=>{
                loader.hide();
                this.$toast.error(error);

            })
        }
    }
     
 }
 </script>
 <style lang="">
     
 </style>