<template>
    <span v-if="status == 1" class="badge badge-primary p-2">Submitted</span>
    <span v-if="status == 9" class="badge badge-dark p-2">Partially Paid</span>
    <span v-if="status == 10" class="badge badge-success p-2">Full Paid</span>
    <span v-if="status == 11" class="badge badge-success p-2">Paid</span>
  </template>
  
  <script>
  export default {
    props: ["status"],
  };
  </script>
  
  <style>
  </style>
  