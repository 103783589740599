<template>
    <div class="row mb-4">
      <div class="col-md-12 text-center">
        <h3 class="main-color">{{ letter.name }}</h3>
        <h6 class="blue-color">{{ letter.motto }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <div class="float-left">
          <h6><strong>Contacts:</strong></h6>
          <p>Tel: {{ letter.telephone }}</p>
          <p>Mobile: {{ letter.phone_number }}</p>
          <p>Email: {{ letter.email }}</p>
          <p>Website: {{ letter.website }}</p>
        </div>
      </div>
      <div class="col-md-2">
        <img :src="letter.logo" alt="" height="100" width="100" />
      </div>
      <div class="col-md-5">
        <div class="float-right">
          <p>{{ letter.address }}</p>
          <p>{{ letter.physical_address }}</p>
          <p>Dar es Salaam, Tanzania</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        letter: {
          name: "",
          email: "",
          tin: "",
          vrn: "",
          phone_number: "",
          telephone: "",
          address: "",
          physical_address: "",
          logo: "",
          motto: "",
          website: "",
          short_name: "",
        },
      };
    },
    created() {
      this.getHeader();
    },
    methods: {
      getHeader() {
        axios
          .get("/letter-header")
          .then((response) => {
            if (response.data.success) {
              this.letter = response.data.letter;
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  .main-color {
    color: #7f1828;
  }
  p {
    margin: 0px;
  }
  .blue-color {
    color: #374878;
  }
  </style>
  