<template lang="">
   <div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="p-5 my-5 bg-light rounded">
                <h2>TERNET</h2>
                    <p>TERNET is a network of Tanzania higher education and research institutions aiming at providing network infrastructure and associated services
                         for enabling sharing of education and research resources inside and outside the country.</p>
            </div>
        </div>
    </div>
   </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>