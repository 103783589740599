<template lang="">
     <div class="container">
          <div class="row">
               <div class="col-md-5">
                    <div class="card">

                         <div class="card-header bg-white">
                              <h4>Profile</h4>
                         </div>

                         <div class="card-body">
                              <div class="table-responsive">
                                   <table class="table table-bordered">
                                      <thead>
                                        <tr>
                                             <th>Name</th>
                                             <td>{{customer.name}}</td>
                                        </tr>
                                        <tr>
                                             <th>Login Email</th>
                                             <td>{{customer.email}}</td>
                                        </tr>
                                        <tr>
                                             <th>TIN</th>
                                             <td>{{customer.tin}}</td>
                                        </tr>
                                      
                                        <h4 class='my-4'>Contact Info</h4>
                                        <tr>
                                             <th>Email</th>
                                             <td>{{customer.contact.email}}</td>
                                        </tr>
                                        <tr>
                                             <th>Phone number</th>
                                             <td>{{customer.contact.phone_number}}</td>
                                        </tr>
                                        <tr>
                                             <th>Telephone</th>
                                             <td>{{customer.contact.telephone_number}}</td>
                                        </tr>
                                        <tr>
                                             <th>Address</th>
                                             <td>{{customer.contact.address}}</td>
                                        </tr>
                                      </thead>

                                   </table>
                              </div>
                         </div>

                         <div class="card-footer d-flex justify-content-center">
                              <a href="#" @click.prevent='passwordModal=true'>Change your password</a>
                         </div>

                    </div>
               </div>
               <div class="col-md-7">
                    <div class="card">
                         <div class="card-header bg-white">
                              <h4>Edit Profile</h4>
                         </div>

                         <div class="card-body">
                              <div class="table-responsive">
                                   <table class="table table-bordered">
                                      <thead>
                                        <tr>
                                             <th>Name</th>
                                             <td>
                                                  <input type="text" disabled v-model='customer.name' class="form-control">
                                             </td>
                                        </tr>
                                        <tr>
                                             <th>Login Email</th>
                                             <td>
                                                  <input type="text" v-model='customer.email' class="form-control">
                                             </td>
                                        </tr>
                                        <tr>
                                             <th>TIN</th>
                                             <td>
                                                  <input type="text" disabled v-model='customer.tin' class="form-control">
                                             </td>
                                        </tr>
                                      
                                        <tr>
                                             <th>
                                                  <button @click.prevent='updateProfile' class='btn btn-danger'>Update Profile</button>
                                             </th>
                                             
                                        </tr>
                                 
                                      </thead>

                                   </table>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <m-modal v-if='passwordModal' @close='passwordModal=false'>
          <template v-slot:header><h4>Change Password</h4></template>
          <template v-slot:body>
               <div class="row mb-3">
                    <div class="col-md-12">
                         <label for="current_password" class='form-label'>Current Password</label>
                         <input type="password" name='current_password' v-model='current_password' class="form-control">
                    </div>
               </div>
               <div class="row mb-3">
                    <div class="col-md-6">
                         <label for="password" class='form-label'>New Password</label>
                         <input type="password" name='password' v-model='password' class="form-control">
                    </div>
                    <div class="col-md-6">
                         <label for="password_confirmation" class='form-label'>Confirm Password</label>
                         <input type="password" name='password_confirmation' v-model='password_confirmation'  class="form-control">
                    </div>
               </div>

          </template>
          <template v-slot:footer>
               <button class="btn btn-primary" @click='changePassword'>Change Password</button>
          </template>
          </m-modal>
     </div>
</template>
<script>
import axios from 'axios';
import {mapActions,mapGetters} from 'vuex'
export default {
     data(){
          return{
             password:"",
             current_password:"",
             password_confirmation:'',
             passwordModal:false
          }
     },

     computed:{
          ...mapGetters(['customer'])
     },
     methods:{
          ...mapActions(['getCustomer']),

          updateProfile(){
               let data={
                    email:this.customer.email
               };
               let loader=this.$loading.show();
               axios.post('/update-profile',data)
               .then(response=>{
                    loader.hide();
                    if(response.data.success){
                         this.$toast.success(response.data.message);
                         this.getCustomer();
                    }else{
                         this.$toast.error(response.data.message);
                    }

               }).catch(error=>{
                    loader.hide();
                    console.log(error);
               });
          },
          closeModal(){
               this.passwordModal=false;
               this.password="",
               this.password_confirmation="",
               this.current_password="";

          },
          changePassword(){
               let data={
                    password:this.password,
                    current_password:this.current_password,
                    password_confirmation:this.password_confirmation
               };
               let loader=this.$loading.show();
               axios.post('/change-password',data)
               .then(response=>{
                    loader.hide();
                    if(response.data.success){
                         this.closeModal();
                         this.$toast.success(response.data.message);
                    }else{
                         this.$toast.error(response.data.message);
                    }
               }).catch(error=>{
                    loader.hide();
                    this.$toast.error(error);
               })
          }
     },

     created(){
          this.getCustomer();
     }
    
}
</script>
<style lang="">
    
</style>