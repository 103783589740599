<template lang="">
    <div class="container">
   <div class="row">
     <div class="col-md-4 offset-4">
      <div class="alert alert-success alert-dismissible fade show" role="alert" v-if='message'>
        {{message}}
 
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
       <div class="card">
         <div class="card-header">
           <h4>Forgot Your Password</h4>
         </div>
         <div class="card-body">
           <div class="mb-3">
             <label for="email" class="form-label">Email</label>
             <input type="text" placeholder="Email" v-model='email' class="form-control">
           </div>
        
 
           <div class="mb-3">
             <button @click='sendResetLink' class="btn btn-danger w-100">Send Reset Code</button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
 </template>
 <script>
import axios from 'axios';

 export default {
  data(){
    return{
      email:"",
      message:"",
      token:""
    }
  },
   methods:{
     sendResetLink(){
      let data={
      email:this.email
    };
    let loader=this.$loading.show();
    axios.post('/forgot-password',data)
    .then(response=>{
      loader.hide();
        if(response.data.success){
          this.$toast.success(response.data.message);
          const token=response.data.token;
          this.$router.push({ name: 'verifyCode', params: { token } })

        }else{
          this.$toast.error(response.data.message);
        }
    }).catch(error=>{
      loader.hide();
      this.$toast.error(error);
    })
     }
   }

     
 }
 </script>
 <style lang="">
     
 </style>