<template lang="en">
   <LetterHeader/>

   <div class="row p-2 mt-5">  
        <div class="col-md-4">
          From
          <address>
            <strong> {{ invoice.organization.short_name }}</strong
            ><br />
            <strong>TIN:</strong> {{ invoice.organization.tin }}<br />
            <strong>VRN:</strong> {{ invoice.organization.vrn }}<br />

            <strong>Address:</strong>
            {{ invoice.organization.contact.address }}<br />
            <strong>Email:</strong> {{ invoice.organization.contact.email }}
          </address>
        </div>
        <!-- /.col -->
        <div class="col-md-4">
          To
          <address>
            <strong>{{ invoice.branch.name }}</strong
            ><br />
            <strong>Address:</strong> {{ invoice.branch.address }},{{
              invoice.branch.physical_address
            }}<br />
            <strong>TIN:</strong> {{ invoice.branch.tin }},<br />
            <strong>Phone:</strong> {{ invoice.branch.phone_number }},
            <br />
            <strong>Email:</strong> {{ invoice.branch.email }}
          </address>
        </div>
        <!-- /.col -->
        <div class="col-md-4">
          <b>Invoice #{{ invoice.invoice_number }}</b
          ><br />
          <b>Date:</b> {{ invoice.created_at }}
          <br />
          <b>Payment Due:</b> {{ invoice.due_date }}<br />
        </div>
        <!-- /.col -->
      </div>

      <div class="row mt-2">
        <div class="col-md-12 table-responsive">
        
          <div class="m-1">
            <table class="table table-bordered table-sm">
              <thead class="thead-light">
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Subtotal</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in invoice.items" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td style="width: 20%">
                    <table class="table table-sm">
                      <tr>
                        <th>{{ item.quantity }}</th>
                        <td>{{ item.unit }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>{{ item.unit_price }}</td>
                  <td>{{ item.total }}</td>
                
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.col -->
      </div>

      <div class="row mt-5">
        <!-- accepted payments column -->
        <div class="col-md-6">
          <h5>Total Quantity</h5>
        </div>
        <!-- /.col -->
        <div class="col-md-6">
          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <tr>
                <th style="width: 50%">Subtotal:</th>
                <td>{{ invoice.total }}</td>
              </tr>
              <tr>
                <th>VAT (18%)</th>
                <td>{{ invoice.vat }}</td>
              </tr>
              <tr>
                <th>Total:</th>
                <td>{{ invoice.total_amount }}</td>
              </tr>
              <tr>
                <th>In Words:</th>
                <td>{{ invoice.amount_in_words }}</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- /.col -->
      </div>

      <!-- <div class="mt-5">
        <p class="text-danger">
          1. Invoice is strictly payable within 30 days after the invoice date.
        </p>
        <p class="text-danger">
          2. Late payment will attract interest and penalties at prescribed rate
        </p>
      </div> -->

      <div class="row mt-5">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <p class="lead">
            <strong>Bank Details:</strong>
          </p>
          <p>
            Account Name:
            {{ invoice.organization.account.account_name }}
          </p>
          <p>
            Bank Name:
            {{ invoice.organization.account.bank }}
          </p>
          <p>
            Account No:
            {{ invoice.organization.account.account_number }}
          </p>

          <p>
            Currency:
            {{ invoice.organization.account.currency }}
          </p>
        </div>
      </div>
</template>
<script>
import LetterHeader from '../partials/LetterHeader.vue';
export default {
   props:['invoice'],
   components:{
      LetterHeader
   }
    
}
</script>
<style lang="">
    
</style>